import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from 'react-router-dom'

const GallerySection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [gallerySectionData, setGallerySectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const gallerySection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-gallery-section-data/' + company_id + '/' + template_id
                    );
                    const result = await gallerySection.json();

                    setGallerySectionData({ "gallery_data": result && result.gallery_data, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    let websitefont = '';
    let section_details = gallerySectionData && gallerySectionData.section_details

    const images = [];

    const [open, isopen] = useState(false)
    const [index, setindex] = useState()
    const Toggle = (name, idx) => {
        isopen(true);
        setindex(idx);
    }
    var allImg = []
    allImg = gallerySectionData && gallerySectionData.gallery_data && gallerySectionData.gallery_data[gallerySectionData.gallery_data.length - 1];

    return (

        <div>

            <div className="container">
                {section_details && <div className="center_title">
                    <h2 className="heading text-center" style={{ fontFamily: websitefont }}>{section_details.section_title}</h2>
                    <div className="text">
                        <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>}
            </div>

            <section className="section-gallery bg-white awe-parallax" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="gallery  no-padding">
                    <div className="gallery-cat text-center">
                        <ul className="list-inline">

                        </ul>
                    </div>

                    <div className="gallery-content">
                        {allImg && <div className="row">
                            <div className="gallery-isotope col-6 pd-0">
                                <div className="item-size"></div>
                                {allImg.map(function (items, id) {
                                    if (id < 6) {
                                        images.push(config.wbImagesEndpoint + "/" + items)
                                        return (
                                            <div className="item-isotope hotel_pic" key={id}>
                                                <div className="gallery_item">
                                                    <a className="gallery-popup mfp-image page-gallery-image" >
                                                        <img src={config.wbImagesEndpoint + '/' + items} alt="" onClick={() => Toggle(items, id)} alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>}

                        <div className="our-gallery text-center">
                            <Link className="awe-btn awe-btn-default" to={{
                                pathname: config.PATH + 'gallery',
                            }} style={{ fontFamily: websitefont }}
                            >Our Gallery
                    </Link>
                        </div>
                    </div>

                </div>
                {open && (
                    <Lightbox
                        className="test"
                        mainSrc={images[index]}
                        thumbnailWidth='150px'
                        thumbnailHeight='150px'
                        nextSrc={images[(index + 1) % images.length]}
                        prevSrc={images[(index + images.length - 1) % images.length]}
                        onCloseRequest={() => isopen(false)}
                        onMovePrevRequest={() =>
                            setindex((index + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setindex((index + 1) % images.length)
                        } />
                )}
            </section>
        </div>
    )
}

export default GallerySection;