import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const AwardsSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [awardSectionData, setAwardSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const awardSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-award-section-data/' + company_id + '/' + template_id
                    );
                    const result = await awardSection.json();

                    setAwardSectionData({ "awards": result && result.awards, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = awardSectionData && awardSectionData.section_details


    return (
        <div>
            <div className="container">
                {section_details && <div className="center_title">
                    <h2 className="heading text-center" style={{ fontFamily: websitefont }}>{section_details.section_title}</h2>
                    <div className="text">
                        <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>}
            </div>

            <section className="section-room-detail bg-white awe-parallax" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container">
                    <div className="room-detail_compare">
                        <div className="room-compare_content">
                            {awardSectionData && awardSectionData.awards && awardSectionData.awards.awards.length > 0 && <div className="row">
                                {awardSectionData.awards.awards.map(function (item, i) {
                                    return (
                                        <div className="col-sm-4 col-md-3 col-lg-2" key={i}>
                                            <div className="room-compare_item">
                                                <div className="img">
                                                    <a href="#"><img src={config.wbImagesEndpoint + '/' + item} alt={awardSectionData.awards.awards_image_alt} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default AwardsSection;