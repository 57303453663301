import React, { useState, useContext, useEffect } from 'react'
import config from '../../../../src/config.json'
import { CompanyContext } from '../../../global_setting/Store';

const CustomFooterSection = () => {
    const [companyDetails] = useContext(CompanyContext);
    const [customFooterSectionData, setCustomFooterSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const customFooterSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-custom-footer-section-data/' + company_id + '/' + template_id
                    );
                    const result = await customFooterSection.json();

                    setCustomFooterSectionData({ "custom_footer_data": result && result.custom_footer_data, "section_details": result && result.section_details, "all_hotels_data": result && result.all_hotels_data });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = customFooterSectionData && customFooterSectionData.section_details

    return (
        <div>
            <div className="awe-parallax" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container-fluid inquery-info">
                    <div className="row">

                        {customFooterSectionData && customFooterSectionData.custom_footer_data && customFooterSectionData.custom_footer_data.map(function (item, i) {
                            return (
                                <div className="col-md-3" key={i}>
                                    <div className="inquery-wrap">
                                        {item.icon && <span className="inquery-icon-wrap"><i className={item.icon[0].icon}></i></span>}
                                        <div className="inquery-head" style={{ fontFamily: websitefont }}>{item.title}</div>
                                        <div className="info-details" dangerouslySetInnerHTML={{ __html: item.description }} style={{ fontFamily: websitefont }}></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <footer id="footer">
                <div className="footer_bottom">
                    <div className="container">
                        {customFooterSectionData && customFooterSectionData.all_hotels_data && <div style={{ float: "left", marginTop: "15px" }}>&copy; COPYRIGHT © {customFooterSectionData.all_hotels_data[0].hotel_name}. All rights reserved.</div>}
                        <div style={{ float: "right", marginRight: "70px" }}>Powered By <a href="http://www.bookingjini.com/" target="_blank"><img src={config.apiEndpoint + '/public/templateimage/jini_logo.png'} style={{ width: "130px" }} /></a></div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default CustomFooterSection;