import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'

const AboutSection = (props) => {

    const [companyDetails] = useContext(CompanyContext);
    const [aboutSectionData, setAboutSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const aboutSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-about-section-data/' + company_id + '/' + template_id
                    );
                    const result = await aboutSection.json();
                    if (result && result.hotel_about_details) {
                        for (let q = 0; q < result.hotel_about_details.length; q++) {
                            for (let r = 0; r < result.all_hotels_data.length; r++) {
                                if (result.hotel_about_details[q].hotel_id === result.all_hotels_data[r].hotel_id) {
                                    result.hotel_about_details[q].hotel_name = result.all_hotels_data[r].hotel_name
                                }
                            }
                        }
                    }

                    setAboutSectionData({ "hotel_about_details": result && result.hotel_about_details, "section_details": result && result.section_details,"all_hotels_data": result && result.all_hotels_data });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = aboutSectionData && aboutSectionData.section_details

    let display_section_title_des = props && props.data
    let total_property = aboutSectionData && aboutSectionData.all_hotels_data && aboutSectionData.all_hotels_data.length

    return (

        <div>
            {!display_section_title_des && <div className="container">
                {section_details && <div className="center_title">
                    <h2 className="heading text-center" style={{ fontFamily: websitefont }}>{section_details.section_title}</h2>
                    <div className="text">
                        <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>}
            </div>}
        <section className="section-home-about bg-white awe-parallax" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
            <div className="container">
                <div className="home-about">
                {aboutSectionData && aboutSectionData.hotel_about_details && aboutSectionData.hotel_about_details.length > 0 && 
                    <OwlCarousel
                        className="owl-theme"
                        items={1}
                        loop
                        nav
                    >
                        {aboutSectionData.hotel_about_details.map(function (item, id) {
                            let send_hotel_name = item.hotel_name.replace(/[^a-zA-Z ]/g, "");
                            send_hotel_name = send_hotel_name.replace(/ +/g, "_");
                            send_hotel_name = send_hotel_name.replace(",", "");
                            send_hotel_name = send_hotel_name.toLowerCase();
                            return (
                                <div className="row" key={id}>
                                    <div className="col-md-6">
                                        <div className="img" >
                                            <a ><img src={config.wbImagesEndpoint + '/' + item.image_name} className="hvr" alt={item.about_image_alt} /></a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text">
                                            <h1 className="heading" style={{ fontFamily: websitefont }}>{item.title}</h1>
                                            <span></span>
                                            <p dangerouslySetInnerHTML={{ __html: item.description }} ></p>

                                            {total_property &&
                                                (total_property > 1) ?
                                                <Link className="awe-btn awe-btn-default" to={{
                                                    pathname: config.PATH + '' + send_hotel_name,
                                                    commonpage: item.hotel_id,
                                                }} style={{ fontFamily: websitefont }}
                                                >READ MORE
                                            </Link>
                                                :
                                                !display_section_title_des && <Link className="awe-btn awe-btn-default" to={{
                                                    pathname: config.PATH + 'about',
                                                }} style={{ fontFamily: websitefont }}
                                                >READ MORE
                                            </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </OwlCarousel>}
                </div>
            </div>
        </section>
        </div>
    )
}

export default AboutSection;
