import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import _debounce from 'lodash.debounce'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import VideoComponent from './VideoComponent';

function Slider() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-slider-data/' + company_id + '/' + template_id
                        // config.apiEndpoint1 + '/userwb/get-slider-data/1685/2'
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                    
                    setwebsiteDetails({ "slider_details": result.slider_details, "selected_benefits": result.selected_benefits, "video_details": result.video_details,'default_value':result && result.slider_default });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
   
    let fromdate = moment();
    let todate = moment(fromdate).add(1, 'days');
    const websitefont = websiteDetails && websiteDetails.template_identity && websiteDetails.template_identity[0].theme_font

    let hotelId;
    
    let to_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    var iFrame
    if (isNaN(from_date) && isNaN(to_date)) {
    }
    else {
        let allData = from_date + "|" + to_date + '|' + hotelId + '|' + "" + '|' + "" + '|' + "" + '|' + true
        let encryptData = btoa(allData);
        iFrame = encryptData
    }
    // let websiteUrl = "https://youtu.be/izzI2mteOOs"
    let websiteUrl = websiteDetails && websiteDetails.video_details && websiteDetails.video_details.video_url
    websiteUrl = websiteUrl && websiteUrl.slice(websiteUrl.indexOf("."), websiteUrl.length)
    websiteUrl = websiteUrl && websiteUrl.slice(websiteUrl.indexOf("/") + 1, websiteUrl.length)


    return (

        <div>
            {websiteDetails && websiteDetails.default_value && (websiteDetails.default_value.default_value === "video") ?
                <div>
                    <VideoComponent />
                </div> :
                <section className="section-slider">

                    <Carousel
                        infiniteLoop 
                        autoPlay={true}
                        interval={3000}
                        animateOut='fadeOut'
                    >
                        {websiteDetails && websiteDetails.slider_details.map(function (item, i) {

                            return (
                                <div className="slider-responsive" key={i}>
                                    <img src={config.wbImagesEndpoint + '/' + item.slider_image} alt={item.slider_image_alt} />
                                    <div className="slider-text">
                                        <h3 className="slider-caption slider-caption-1" style={{ fontSize: "45px", textAlign: "center",fontFamily:websitefont }}>{item.slider_caption}</h3>
                                    </div>
                                </div>

                            )
                        })}
                    </Carousel>
                </section>

            }
        
        </div>

    )
}





export default Slider;