import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'

const ServiceSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [servicesSectionData, setServicesSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let name_hotel;
                    const servicesSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-services-section-data/' + company_id + '/' + template_id
                    );
                    const result = await servicesSection.json();

                    if (result && result.hotel_name) {
                        name_hotel = result.hotel_name.hotel_name.replace(/[^a-zA-Z0-9 ]/g, "");
                        name_hotel = name_hotel.replace(/ +/g, "_");
                        name_hotel = name_hotel.toLowerCase();
                    }

                    setServicesSectionData({ "selected_services": result && result.selected_services, "section_details": result && result.section_details, "hotel_name": name_hotel });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = servicesSectionData && servicesSectionData.section_details

    return (
        <section className="section-accomd awe-parallax bg-14" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
            <div className="awe-overlay"></div>
            <div className="container">
                <div className="accomd-modations">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="accomd-modations-header">
                                {section_details && <h2 className="heading" style={{ marginLeft: "-80px", fontFamily: websitefont }} >{section_details.section_title}</h2>}
                                <p></p>
                            </div>
                        </div>
                        <div className="col-md-8 col-md-offset-1">
                            <div className="accomd-modations-content owl-single">
                                <div className="row">
                                    {servicesSectionData && servicesSectionData.selected_services && servicesSectionData.selected_services.map(function (item, i) {
                                        var send_title = item.title.replace(/[^a-zA-Z0-9 ]/g, "");
                                        send_title = send_title.replace(/ +/g, "_");
                                        send_title = send_title.toLowerCase();
                                        if (i < 3) {
                                            return (
                                                <div className="col-xs-6" key={i}>
                                                    <div className="accomd-modations-room">
                                                        <div className="img">
                                                            <Link
                                                                to={{
                                                                    pathname: config.PATH + 'page',
                                                                    search: servicesSectionData.hotel_name + '/' + send_title
                                                                }}
                                                            >
                                                                <img src={config.wbImagesEndpoint + '/' + item.services_image} alt={item.services_image_alt} />
                                                            </Link>
                                                        </div>
                                                        <div className="text">
                                                            <h2>
                                                                <Link
                                                                    to={{
                                                                        pathname: config.PATH + 'page',
                                                                        search: servicesSectionData.hotel_name + '/' + send_title
                                                                    }}
                                                                >{item.title}</Link>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;