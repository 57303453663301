import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'

const BlogsSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [blogSectionData, setBlogSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const blogSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-blog-section-data/' + company_id + '/' + template_id
                    );
                    const result = await blogSection.json();

                    setBlogSectionData({ "blog_data": result && result.hotel_blogs, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = blogSectionData && blogSectionData.section_details

    return (
        <div>

            <section className="section-accomd awe-parallax bg-14" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="awe-overlay"></div>
                <div className="container">
                    <div className="accomd-modations">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="accomd-modations-header">
                                    {section_details && <h2 className="heading" style={{ marginLeft: "-80px", fontFamily: websitefont }} >{section_details.section_title}</h2>}
                                    <p></p>
                                </div>
                            </div>
                            <div className="col-md-8 col-md-offset-1">
                                <div className="accomd-modations-content owl-single">
                                    {blogSectionData && blogSectionData.blog_data && blogSectionData.blog_data.length > 0 && <div className="row">
                                        {blogSectionData.blog_data.map(function (item, i) {
                                            if (i < 4) {
                                                var send_title = item.blog_title.replace(/[^a-zA-Z0-9 ]/g, "");
                                                send_title = send_title.replace(/ +/g, "_");
                                                send_title = send_title.replace(",", "");
                                                send_title = send_title.toLowerCase();
                                                return (
                                                    <div className="col-xs-6" key={i}>
                                                        <div className="accomd-modations-room">
                                                            <div className="img">
                                                                <Link to={{
                                                                    pathname: config.PATH + '' + send_title
                                                                }}>
                                                                    <img src={config.wbImagesEndpoint + '/' + item.blog_image} alt={item.blog_image_alt} />

                                                                </Link>
                                                            </div>
                                                            <div className="text">
                                                                <h2>
                                                                    <Link to={{
                                                                        pathname: config.PATH + '' + send_title
                                                                    }}>Read More</Link>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}

                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default BlogsSection;