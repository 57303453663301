import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import FooterFixed from './FooterFixed'
import CustomFooterSection from './sections/CustomFooterSection';

function EventDetails() {
    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [eventDetailsPageData, setEventDetailsPageData] = useState();

    var hotel_event_url;
    hotel_event_url = window.location.pathname;
    hotel_event_url = hotel_event_url.replace(config.PATH, "");

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let event_details_det;
                    let display_default_footer = true;
                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-event-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.event_page_data && result.event_page_data.page_section) {
                        for (let i = 0; i < result.event_page_data.page_section.length; i++) {
                            if (result.event_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }

                    if (result && result.hotel_events) {
                        let check_special_char;
                        for (let i = 0; i < result.hotel_events.length; i++) {
                            check_special_char = result.hotel_events[i].event_title.replace(/[^a-zA-Z0-9 ]/g, "");
                            check_special_char = check_special_char.replace(/ +/g, "_");
                            check_special_char = check_special_char.toLowerCase();

                            if (check_special_char === hotel_event_url) {
                                event_details_det = result.hotel_events[i];
                            }
                        }
                    }


                    setEventDetailsPageData({ "hotel_events": event_details_det, "display_default_footer": display_default_footer });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = ''

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });


    let video_banner_link = eventDetailsPageData && eventDetailsPageData.hotel_events && eventDetailsPageData.hotel_events.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = eventDetailsPageData && eventDetailsPageData.hotel_events && eventDetailsPageData.hotel_events.video_banner


    return (
        <div>
            {(eventDetailsPageData === undefined) ? <div className="loader"></div> : <Header />}


            {eventDetailsPageData && eventDetailsPageData.hotel_events &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{eventDetailsPageData.hotel_events.seo_title}</title>
                    <meta name="description" content={eventDetailsPageData.hotel_events.seo_description} />
                </MetaTags>
            }

            {eventDetailsPageData && eventDetailsPageData.hotel_events && eventDetailsPageData.hotel_events.banner &&
                <section className="section-sub-banner awe-parallax bg-9" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + eventDetailsPageData.hotel_events.banner + ")" }}>
                    <div className="awe-overlay"></div>
                    <div className="sub-banner">
                        <div className="container">
                            <div className="text text-center">
                                <h2>{eventDetailsPageData.hotel_events.banner_title}</h2>
                            </div>
                        </div>
                    </div>
                </section>}



            {eventDetailsPageData && eventDetailsPageData.hotel_events && eventDetailsPageData.hotel_events.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + eventDetailsPageData.hotel_events.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + eventDetailsPageData.hotel_events.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {eventDetailsPageData && eventDetailsPageData.hotel_events && eventDetailsPageData.hotel_events.page_title &&
                <div className="container">
                    <div className="center_title">
                        <h2 className="heading text-center">{eventDetailsPageData.hotel_events.page_title}</h2>
                        <div className="text">
                            <p dangerouslySetInnerHTML={{ __html: eventDetailsPageData.hotel_events.page_description }}></p>
                        </div>
                    </div>
                </div>
            }

            {eventDetailsPageData && eventDetailsPageData.hotel_events &&
                <section className="section-about" >
                    <div className="container">
                        <div className="about">
                            <div className="about-item about-right">
                                <div className="img" style={{ width: "40%" }}>
                                    <img src={config.wbImagesEndpoint + '/' + eventDetailsPageData.hotel_events.event_image} alt={eventDetailsPageData.hotel_events.event_image_alt} />
                                </div>
                                <div className="text" style={{ width: "60%", marginTop: "0px" }}>
                                    <h2 className="heading" style={{ fontSize: "20px", fontFamily: websitefont }}>{eventDetailsPageData.hotel_events.event_title}</h2>
                                    <div className="desc">
                                        <p dangerouslySetInnerHTML={{ __html: eventDetailsPageData.hotel_events.event_description }} ></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {eventDetailsPageData && eventDetailsPageData.display_default_footer === false && <CustomFooterSection />}

            {eventDetailsPageData && eventDetailsPageData.display_default_footer === true && <Footer />}
            {eventDetailsPageData && <FooterFixed />}
        </div>
    )
}
export default EventDetails;