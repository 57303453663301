import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import history from '../../history'
import config from '../../../src/config.json'

function NotFound() {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [notfoundPageData, setNotFoundPageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let image = '';
                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-notfound-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.notfound_page_data && result.notfound_page_data.image_name) {
                        image = result.notfound_page_data.image_name[0].image_name;
                    }

                    setNotFoundPageData({ "bg_image": image })
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    return (
        <div>
            {(notfoundPageData === undefined) ? <div className="loader"></div> : <Header />}
            { notfoundPageData && notfoundPageData.bg_image && <section className="error_area" style={{ backgroundImage: "url(" + config.imageEndpoint + '/' + notfoundPageData.bg_image + ")" }}>
                <div className="container">
                    <div className="error_inner_text_area">
                        <div className="error_inner_text">
                            <h3>404</h3>
                            <h4>Oops! That page can’t be found</h4>
                            <h5>Sorry, the page you are looking for does not exist</h5>
                            <a className="awe-btn awe-btn-default" onClick={() => history.push(config.PATH + 'home')}>Go to home page</a>
                        </div>
                    </div>
                </div>
            </section>}
            <Footer />
        </div>

    )
}
export default NotFound;


