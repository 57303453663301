import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'

function FooterFixed() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-fixed-footer-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    if(result.room_details.data){
                        var all_rooms={data:{}};
                        var all_hotel_rooms=[]
                        for(var i = 0;i<result.room_details.data.length;i++){
                          for(var j =0;j<result.room_details.data[i].length;j++){
                            all_hotel_rooms.push(result.room_details.data[i][j]);
                          }
                        }
                        all_rooms.data=all_hotel_rooms;
                    }

                    let numbers=[];
                    if (result && result.hotel_details) {
                        numbers=result.hotel_details.original[0].mobile.split(',');
                    }
                    setwebsiteDetails({ "template_identity":result.template_identity,"hotel_menus":result.hotel_menu_details,"room_details":all_rooms,"hotel_details":result.hotel_details,"phone_numbers":numbers });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    

    return (
        <div>
           {websiteDetails && websiteDetails.phone_numbers.length>0 &&  <a href={"tel:" + websiteDetails.phone_numbers[0]} id="callnowbutton"><div className="text-center"><i className="fa fa-phone"></i></div></a>  }             
        </div>
    )
}
export default FooterFixed;