import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import _debounce from 'lodash.debounce'
import moment from 'moment';
import history from '../../history';

function Header() {
    // document.oncontextmenu = document.body.oncontextmenu = function () { return false; }
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    const [hotelname, setHotelName] = useState();
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-header-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;
                    if (result && result.header_menu_details.length > 5) {
                        let header_menu = result.header_menu_details;
                        var extra_header_menu = header_menu.splice(6, 6);

                        extra_header_menu.forEach(function (item, i) {
                            item["menu"] = "active";
                        })
                    } else {
                        extra_header_menu = [];
                    }

                    var all_menu_details = {
                        "header": {
                            "header_menus": result && result.header_menu_details,
                            "other_menus": extra_header_menu
                        }
                    }

                    var check_special_char = result && result.hotel_menu_details[0].hotel_name.replace(/[^a-zA-Z0-9 ]/g, "");
                    var check_space = check_special_char.replace(/ +/g, "_");
                    var final_type = check_space.toLowerCase();


                    let numbers=[];
                    let emails=[];
                    if (result && result.hotel_details) {
                        numbers=result.hotel_details.original[0].mobile.split(',');
                        emails=result.hotel_details.original[0].email_id.split(',');
                    }



                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details, "services": result && result.service_details && result.service_details.original,"phone_numbers":numbers,"emails":emails });
                    setHotelName(result && result.hotel_menu_details && final_type);

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    var hotelDetails = websiteDetails && websiteDetails.hotel_details && websiteDetails.hotel_details.original[0]
    const [screen_size, setScreensize] = useState(window.innerWidth)
    const [dropdowntoggle, settoggle] = useState(false)

    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });
    const [headerButton, setHeaderButton] = useState(false);

    //function use to redirect the page
    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }
    }

    const mobileHeaderToggle = () => {
        if (headerButton) {
            setHeaderButton(false);
        }
        else {
            setHeaderButton(true);
        }
    }


    let hotelId = websiteDetails && websiteDetails.hotel_menus && websiteDetails.hotel_menus[0].hotel_id
    let fromdate = moment();
    let todate = moment(fromdate).add(1, 'days');
    let to_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    var iFrame
    if (isNaN(from_date) && isNaN(to_date)) {
    }
    else {
        let allData = from_date + "|" + to_date + '|' + hotelId + '|' + "" + '|' + "" + '|' + "" + '|' + true
        let encryptData = btoa(allData);
        iFrame = encryptData
    }

    return (
        <div >
            <header id="header">
                <div className="header_top">
                    <div className="container">
                        {hotelDetails &&
                            <div className="header_left float-left">
                                <span style={{ color: "#fff" }}><i className="fa fa-map-marker"></i>{hotelDetails.hotel_address}</span>

                                {websiteDetails && websiteDetails.phone_numbers.length>0 && <span style={{ color: "#fff" }}><i className="fa fa-phone"></i><a href={"tel:" + websiteDetails.phone_numbers[0]} style={{ color: "inherit"}}>{websiteDetails.phone_numbers[0]}</a></span>}

                                {websiteDetails && websiteDetails.emails.length>0 && 
                                <span style={{ color: "#fff" }}><i className="fa fa-envelope"></i><a href={"mailto:" + websiteDetails.emails[0]} style={{ color: "inherit" }} >{websiteDetails.emails[0]}</a> </span>}


                            </div>
                        }
                        <div className="header_right float-right">
                            <span className="login-register">
                                {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                    if (i < 2) {
                                        return (
                                            <a onClick={() => redirectTo(item)} style={{ fontFamily: websitefont }} key={i}>{item.menu_name}
                                            </a>
                                        )
                                    }
                                })}

                            </span>
                        </div>
                    </div>
                    <div className={(screen_size > 1200) ? "header_content" : "header_mobile"} id="header_content">
                        <div className="container">
                            {websiteDetails && websiteDetails.template_identity &&
                                <div className="header_logo">
                                    <a onClick={() => history.push(config.PATH + 'home')}>
                                        <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt} />
                                    </a>
                                </div>
                            }
                            <nav className={(headerButton) ? "header_menu active" : "header_menu"}>


                                <ul className="menu">
                                    {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                        if (item.menu_name.includes('services') || item.menu_name.includes('Services')) {
                                            return (
                                                <li key={i}><a >{item.menu_name}</a>
                                                    <ul className="sub-menu">
                                                        {websiteDetails.services && websiteDetails.services.map(function (items, id) {
                                                            var send_title = items.title.replace(/[^a-zA-Z0-9 ]/g, "");
                                                            send_title = send_title.replace(/ +/g, "_");
                                                            send_title = send_title.toLowerCase();
                                                            return (
                                                                <li key={id}>
                                                                    <Link to={{
                                                                        pathname: config.PATH + 'page',
                                                                        search: hotelname + '/' + send_title
                                                                    }}>{items.title}</Link>
                                                                </li>

                                                            )
                                                        })
                                                        }

                                                    </ul>
                                                </li>
                                            )
                                        }
                                        else if(item.menu_name == "Hotels"){
                                            return(
                                                <li className={(dropdowntoggle) ? "dropdown submenu open" : "dropdown submenu"} key={i}>
                                                <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded={(dropdowntoggle) ? "true" : "false"} onClick={() => (dropdowntoggle) ? settoggle(false) : settoggle(true)} style={{ fontFamily: websitefont }}>
                                                    {item.menu_name}</a>
                                                <ul className="sub-menu">
                                                    {websiteDetails && websiteDetails.hotel_menus.map(function (item, i) {
                                                    var send_hotel_name = item.hotel_name.replace(/[^a-zA-Z ]/g,"");
                                                        send_hotel_name = send_hotel_name.replace(/ +/g, "_");
                                                        send_hotel_name = send_hotel_name.toLowerCase();
                                                        return (
                                                            <li key={i}
                                                            >
                                                                <Link
                                                                    to={{
                                                                        pathname: config.PATH + send_hotel_name,
                                                                    }}
                                                                >{item.hotel_name}
                                                                </Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                </li>
                                            )

                                        }
                                        else {
                                            return (
                                                <li key={i}><a onClick={() => redirectTo(item)} style={{ fontFamily: websitefont }} key={i}>{item.menu_name}
                                                </a></li>
                                            )
                                        }
                                    })}


                                    
                                    <li><a><i className="fa fa-bars" aria-hidden="true"></i></a>
                                        <ul className="sub-menu">
                                            {websiteDetails && websiteDetails.menu_details
                                                && websiteDetails.menu_details.header.other_menus.length > 0 && websiteDetails.menu_details.header.other_menus.map(function (item, i) {
                                                    return (
                                                        <li key={i}>
                                                            <a onClick={() => redirectTo(item)}
                                                                style={{ fontFamily: websitefont }}>{item.menu_name}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>

                                    <li>
                                        {/* <Link className="header-booknow-button"
                                        to={{
                                            pathname: config.PATH + 'booknow',
                                            search: '?' + (iFrame),
                                        }}
                                    >Book Now</Link> */}

                                    <a className="header-booknow-button" href={"https://" + companyDetails.subdomain_name}>Book Now</a>

                                    </li>
                                </ul>


                            </nav >
                            <span className={(headerButton) ? "menu-bars active" : "menu-bars"} onClick={() => mobileHeaderToggle()}>
                                <span></span>
                            </span>

                        </div>
                    </div>
                </div>
            </header >
        </div >
    )
}

export default Header