import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import FooterFixed from './FooterFixed'
import CustomFooterSection from './sections/CustomFooterSection';


function BlogDetails() {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [blogDetailsPageData, setBlogDetailsPageData] = useState();

    var hotel_blog_url;
    hotel_blog_url = window.location.pathname;
    hotel_blog_url = hotel_blog_url.replace(config.PATH, "");

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let blog_details_det;
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-blog-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.blog_page_data && result.blog_page_data.page_section) {
                        for (let i = 0; i < result.blog_page_data.page_section.length; i++) {
                            if (result.blog_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }


                    if (result && result.hotel_blogs) {
                        let check_special_char;
                        for (let i = 0; i < result.hotel_blogs.length; i++) {
                            check_special_char = result.hotel_blogs[i].blog_title.replace(/[^a-zA-Z0-9 ]/g, "");
                            check_special_char = check_special_char.replace(/ +/g, "_");
                            check_special_char = check_special_char.toLowerCase();

                            if (check_special_char === hotel_blog_url) {
                                blog_details_det = result.hotel_blogs[i];
                            }
                        }
                    }


                    setBlogDetailsPageData({ "hotel_blogs": blog_details_det, "recent_blogs": result && result.hotel_blogs, "all_blogs": result && result.hotel_blogs,"display_default_footer": display_default_footer });


                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    const [search, setsearch] = useState('');
    const [searchtitle, setsearchtitle] = useState('');
    const [searchdes, setsearchdes] = useState('');
    const [searchdate, setsearchdate] = useState('');
    const [valid, setvalid] = useState(false);
    const [active, setactive] = useState(false)
    const Search = () => {
        if (search === '') {
            setactive(false);
            setvalid(true);
        } else {
            setvalid(false);
            blogDetailsPageData && blogDetailsPageData.all_blogs && blogDetailsPageData.all_blogs.map(function (item, i) {
                var searchdate = new Date(item.created_at);
                var search_date = searchdate.toDateString().substr(4, 11);
                var titl = item.blog_title.toLowerCase();
                var desc = item.blog_description.toLowerCase();
                var searchd = search.toLowerCase();
                var dates = search_date.toLowerCase();
                if (titl.includes(searchd) || desc.includes(searchd) || dates.includes(searchd)) {
                    setactive(true);
                    setsearchtitle(item.blog_title);
                    setsearchdes(item.blog_description);
                    setsearchdate(search_date);
                }
                else {
                    setactive(true);
                    setsearchtitle('');
                    setsearchdes('');
                    setsearchdate('');

                }
            })
        }

    }



    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });


    let video_banner_link = blogDetailsPageData && blogDetailsPageData.hotel_blogs && blogDetailsPageData.hotel_blogs.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = blogDetailsPageData && blogDetailsPageData.hotel_blogs && blogDetailsPageData.hotel_blogs.video_banner


    return (
        <div>


            {(blogDetailsPageData === undefined) ? <div className="loader"></div> : <Header />}

            {blogDetailsPageData && blogDetailsPageData.hotel_blogs &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{blogDetailsPageData.hotel_blogs.seo_title}</title>
                    <meta name="description" content={blogDetailsPageData.hotel_blogs.seo_description} />
                </MetaTags>
            }

            {blogDetailsPageData && blogDetailsPageData.hotel_blogs && blogDetailsPageData.hotel_blogs.banner &&
                <section className="section-sub-banner awe-parallax bg-9" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + blogDetailsPageData.hotel_blogs.banner + ")" }}>
                    <div className="awe-overlay"></div>
                    <div className="sub-banner">
                        <div className="container">
                            <div className="text text-center">
                                <h2>{blogDetailsPageData.hotel_blogs.banner_title}</h2>
                            </div>
                        </div>
                    </div>
                </section>}


            {blogDetailsPageData && blogDetailsPageData.hotel_blogs && blogDetailsPageData.hotel_blogs.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + blogDetailsPageData.hotel_blogs.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + blogDetailsPageData.hotel_blogs.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}



            {blogDetailsPageData && blogDetailsPageData.hotel_blogs && blogDetailsPageData.hotel_blogs.page_title &&
                <div className="container">
                    <div className="center_title">
                        <h2 className="heading text-center">{blogDetailsPageData.hotel_blogs.page_title}</h2>
                        <div className="text">
                            <p dangerouslySetInnerHTML={{ __html: blogDetailsPageData.hotel_blogs.page_description }}></p>
                        </div>
                    </div>
                </div>
            }


            {blogDetailsPageData && <section className="blog_details_area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 pull-right">
                            <div className="blog_details_inner">
                                {blogDetailsPageData && blogDetailsPageData.hotel_blogs &&
                                    <div className="blog_item" >
                                        <a href="#" className="blog_img">
                                            <img src={config.wbImagesEndpoint + '/' + blogDetailsPageData.hotel_blogs.blog_image} alt={blogDetailsPageData.hotel_blogs.blog_image_alt} />
                                        </a>
                                        <div className="blog_text">
                                            <a href="#"><h4>{blogDetailsPageData.hotel_blogs.blog_title}</h4></a>
                                            <ul>
                                            </ul>
                                            <p dangerouslySetInnerHTML={{ __html: blogDetailsPageData.hotel_blogs.blog_description }}></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-3 pull-right">
                            <div className="sidebar_area">
                                <aside className="r_widget search_widget">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Search Keywords" value={search} onChange={(e) => setsearch(e.target.value)} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-default" type="button" onClick={() => Search()}><i className="icon icon-Search fa fa-search"></i></button>
                                        </span>
                                    </div>
                                    <div hidden={valid === false} style={{ color: 'red' }}>Please Enter Keywords !!!</div>
                                </aside>

                                {(active) ?
                                    <aside className="r_widget recent_widget">
                                        <div className="r_widget_title">
                                            <h3>Recent News</h3>
                                        </div>
                                        <div className="recent_inner">
                                            {blogDetailsPageData && blogDetailsPageData.all_blogs && blogDetailsPageData.all_blogs.map(function (item, i) {
                                                var rdate = new Date(item.created_at);
                                                var rcreate_date = rdate.toDateString().substr(4, 11);
                                                if (item.blog_title === searchtitle || item.blog_description === searchdes || rcreate_date === searchdate) {
                                                    return (
                                                        <div className="recent_item" key={i}>
                                                            <Link className="blog_img" to={{
                                                                pathname: config.PATH + "blogdetails",
                                                                search: '?' + item.content_id,
                                                            }}><h4>{item.blog_title}</h4></Link>
                                                            <h5>{rcreate_date}</h5>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div className="recent_item" key={i}>
                                                            <h4>No Results Found !!!</h4>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </aside>
                                    : <aside className="r_widget recent_widget">
                                        <div className="r_widget_title">
                                            <h3>Recent News</h3>
                                        </div>
                                        <div className="recent_inner">
                                            {blogDetailsPageData && blogDetailsPageData.all_blogs && blogDetailsPageData.all_blogs.map(function (item, i) {
                                                var rdate = new Date(item.created_at);
                                                var rcreate_date = rdate.toDateString().substr(4, 11);
                                                return (
                                                    <div className="recent_item" key={i}>
                                                        <Link className="blog_img" to={{
                                                            pathname: config.PATH + "blogdetails",
                                                            search: '?' + item.content_id,
                                                        }}>
                                                            <h4>{item.blog_title}</h4></Link>
                                                        {/* <h5>{rcreate_date}</h5> */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </aside>
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </section>}

            {blogDetailsPageData && blogDetailsPageData.display_default_footer === false &&  <CustomFooterSection />}
            {blogDetailsPageData && blogDetailsPageData.display_default_footer === true && <Footer />}
            {blogDetailsPageData && <FooterFixed />}

        </div>
    )
}
export default BlogDetails;