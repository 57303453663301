import React, { useState, useContext } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'


function BookNow(){
    const [companyDetails] = useContext(CompanyContext);
    const [scrolled,setscroll]=useState(false)
    if(scrolled===false){
        window.scrollTo(0,0)
        }
        
    window.addEventListener('scroll', function() {
        setscroll(true);
    });
    var encryptdata;
    const parseURLParams=(url) =>{
        var queryStart = url.indexOf("?") + 1,
            queryEnd = url.indexOf("#") + 1 || url.length + 1;
           encryptdata = url.slice(queryStart, queryEnd - 1);
    }
    let url = window.location.href;
    let get_encoded_url = parseURLParams(url);
    
    return(
        <div>
        {(companyDetails===0)?<div className="loader"></div>:<Header/>}
        <div>
        <iframe style={{height:'100vh',width:'100vw'}}src={"https://" + companyDetails.subdomain_name +"/property?q="+encryptdata}></iframe>
        </div>
        {(companyDetails===0)?'':<Footer/>}

        </div>
    )
}
export default BookNow