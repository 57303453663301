import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import history from '../../history';


function Footer() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-footer-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;

                    var all_menu_details = {
                        "footer": {
                            "footer_menus": result.footer_menu_details,
                        }
                    }


                    let numbers=[];
                    let emails=[];
                    if (result && result.hotel_details) {
                        numbers=result.hotel_details.original[0].mobile.split(',');
                        emails=result.hotel_details.original[0].email_id.split(',');
                    }

                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details,"phone_numbers":numbers,"emails":emails });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    // const [websiteDetails] = useContext(WebsiteDetailsContext);
    const websitefont = websiteDetails && websiteDetails.template_identity && websiteDetails.template_identity[0].theme_font

    var hotelDetails = websiteDetails && websiteDetails.hotel_details && websiteDetails.hotel_details.original[0]

    const redirectTo = (page) => {
       if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if(page_url.includes("www")){
                page_url = page_url.trimStart()
                window.open("http://" + page_url)
                }

                else{
                    history.push(config.PATH + page_url);
                }
            }
        }
    }

    return (

        <footer id="footer">
            <div className="footer_center">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-lg-5">
                            <div className="widget widget_logo">
                                <div className="widget-logo">
                                {websiteDetails && websiteDetails.template_identity &&
                                    <div className="img">
                                        <a onClick={() => history.push(config.PATH + 'home')}><img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt} /></a>
                                    </div>
                                }
                                    {hotelDetails &&
                                    <div className="text">

                                        <p className="footer-address">{hotelDetails.hotel_address}</p>


                                        {websiteDetails && websiteDetails.phone_numbers.length > 0 && websiteDetails.phone_numbers.map(function (items, i) {

                                            return (
                                                <p key={i}><i className="fa fa-phone"></i> <a href={"tel:" + items} style={{ color: "inherit"}} >{items}</a></p>
                                            )
                                        })}

                                        {websiteDetails && websiteDetails.emails.length > 0 && websiteDetails.emails.map(function (items, i) {
                                            return (
                                                <p key={i}><i className="fa fa-envelope-o"></i> <a href={"mailto:" + items} style={{ color: "inherit"}} >{items}</a></p>
                                            )
                                        })}  

                                    </div>
                                    }
                                </div>
                            </div>
                        </div>

                       
                        <div className="col-xs-4 col-lg-4">
                            <div className="widget">
                                <h4 className="widget-title">Menu</h4>
                                <ul>
                                    
                                    {/* <li><a onClick={() => history.push(config.PATH + 'gallery')} style={{fontFamily:websitefont,cursor:"pointer"}}>Gallery</a></li>
                                    <li><a onClick={() => history.push(config.PATH + 'contact')} style={{fontFamily:websitefont,cursor:"pointer"}}>Contact Us</a></li> */}

                                    {websiteDetails && websiteDetails.menu_details.footer.footer_menus.map(function (item, i) {
                                        return (
                                            <li key={i}><a style={{fontFamily:websitefont,cursor:"pointer"}} onClick={() => redirectTo(item)}>{item.menu_name}</a></li>

                                        )})}
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-4 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title">Social Links</h4>
                                <ul className="footer-links-social">
                                        {websiteDetails && hotelDetails && hotelDetails.facebook_link && <li><a className="social-icons-class class-facebook" href={hotelDetails.facebook_link} ><i className="fa fa-facebook"></i></a></li>}
                                        {websiteDetails && hotelDetails && hotelDetails.instagram_link && <li><a className="social-icons-class class-instagram" href={hotelDetails.instagram_link } ><i className="fa fa-instagram"></i></a></li>}
                                        {websiteDetails && hotelDetails && hotelDetails.tripadvisor_link && <li><a className="social-icons-class class-tripadvisor" href={hotelDetails.tripadvisor_link} ><i className="fa fa-tripadvisor"></i></a></li>}
                                        {websiteDetails && hotelDetails && hotelDetails.twitter_link && <li><a className="social-icons-class class-twitter" href={hotelDetails.twitter_link}><i className="fa fa-twitter"></i></a></li>}
                                        {websiteDetails && hotelDetails && hotelDetails.linked_in_link && <li><a className="social-icons-class class-linkedin" href={hotelDetails.linked_in_link}><i className="fa fa-linkedin"></i></a></li>}
                                    </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="footer_bottom">
                <div className="container">
                    <div style={{float: "left", marginTop: "15px"}}>&copy; COPYRIGHT © {hotelDetails && hotelDetails.hotel_name}. All rights reserved.</div>
                    <div style={{float: "right", marginRight: "70px"}}>Powered By <a href="http://www.bookingjini.com/" target="_blank"><img src={config.apiEndpoint + '/public/templateimage/jini_logo.png'} style={{width:"130px"}}/></a></div>
                    </div>
                </div>
            </footer>
    )
}
export default Footer