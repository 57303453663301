import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import FooterFixed from './FooterFixed'
import MetaTags from 'react-meta-tags';
import moment from 'moment'
import CustomFooterSection from './sections/CustomFooterSection';

function RoomDetails() {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [roomDetailsPageData, setRoomDetailsPageData] = useState();


    let hotel_room_url;
    hotel_room_url = window.location.pathname;
    hotel_room_url = hotel_room_url.replace(config.PATH, "");


    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let all_hotel_rooms = [];
                    let display_room_details;
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-room-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.room_details.data) {
                        for (let i = 0; i < result.room_details.data.length; i++) {
                            for (let j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (all_hotel_rooms && result.room_services) {
                        for (let p = 0; p < all_hotel_rooms.length; p++) {
                            for (let q = 0; q < result.room_services.length; q++) {
                                if (all_hotel_rooms[p].hotel_id === result.room_services[q].hotel_id && all_hotel_rooms[p].room_type_id === result.room_services[q].room_type_id) {
                                    all_hotel_rooms[p].room_services = result.room_services[q].room_services;
                                }
                            }
                        }
                    }

                    if (all_hotel_rooms && result.private_washroom) {
                        for (let p = 0; p < all_hotel_rooms.length; p++) {
                            for (let q = 0; q < result.private_washroom.length; q++) {
                                if (all_hotel_rooms[p].hotel_id === result.private_washroom[q].hotel_id && all_hotel_rooms[p].room_type_id === result.private_washroom[q].room_type_id) {
                                    all_hotel_rooms[p].private_washroom = result.private_washroom[q].private_washroom;
                                }
                            }
                        }
                    }


                    for (let k = 0; k < all_hotel_rooms.length; k++) {
                        let final_type = all_hotel_rooms[k].room_type.replace(/[^a-zA-Z ]/g, "");
                        final_type = final_type.replace(/ +/g, "_");
                        final_type = final_type.toLowerCase();
                        if (final_type === hotel_room_url) {
                            display_room_details = all_hotel_rooms[k];
                        }
                    }

                    if (result && result.room_page_data && result.room_page_data.page_section) {
                        for (let i = 0; i < result.room_page_data.page_section.length; i++) {
                            if (result.room_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }

                    setRoomDetailsPageData({ "room_details_data": display_room_details, "display_default_footer": display_default_footer })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    let fromdate = moment();
    let todate = moment(fromdate).add(1, 'days');
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    let hotel_id_booking_engine = roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.hotel_id
    let encryptData

    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id_booking_engine + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData

    }

    let video_banner_link = roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.video_banner

    return (
        <div className="room-details">
            {(roomDetailsPageData === undefined) ? <div className="loader"></div> : <Header />}


            {roomDetailsPageData && roomDetailsPageData.room_details_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{roomDetailsPageData.room_details_data.seo_title}</title>
                    <meta name="description" content={roomDetailsPageData.room_details_data.seo_description} />
                </MetaTags>
            }


            {roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.banner &&
                <section className="section-sub-banner awe-parallax bg-9" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + roomDetailsPageData.room_details_data.banner + ")" }}>
                    <div className="awe-overlay"></div>
                    <div className="sub-banner">
                        <div className="container">
                            <div className="text text-center">
                                <h2>{roomDetailsPageData.room_details_data.banner_title}</h2>
                            </div>
                        </div>
                    </div>
                </section>}



            {roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + roomDetailsPageData.room_details_data.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + roomDetailsPageData.room_details_data.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}

            {roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.page_title && <div className="container">
                <div className="center_title">
                    <h2 className="heading text-center">{roomDetailsPageData.room_details_data.page_title}</h2>
                    <div className="text">
                        <p dangerouslySetInnerHTML={{ __html: roomDetailsPageData.room_details_data.page_description }}></p>
                    </div>
                </div>
            </div>}


            <section className="section-room bg-white">
                <div className="container">
                    <div className="room-wrap-3">
                        {roomDetailsPageData && roomDetailsPageData.room_details_data && <div className="row">
                            <div className="col-lg-10 col-lg-offset-1">
                                <div className="room_item-3">
                                    <div className="img">
                                        <a>
                                            <img src={config.imageEndpoint + '/' + roomDetailsPageData.room_details_data.room_image[0]} alt={roomDetailsPageData.room_details_data.room_type} />
                                        </a>
                                    </div>
                                    <div className="text-thumbs">
                                        <div className="thumbs">
                                            <a><img src={config.imageEndpoint + '/' + roomDetailsPageData.room_details_data.room_image[0]} alt={roomDetailsPageData.room_details_data.room_type} /></a>
                                        </div>
                                        <div className="text">
                                            <h2><a>{roomDetailsPageData.room_details_data.room_type}</a></h2>
                                            <br />
                                            <span className="price">Start from <span className="amout">₹{roomDetailsPageData.room_details_data.rack_price}</span> per day</span>
                                            <p dangerouslySetInnerHTML={{ __html: roomDetailsPageData.room_details_data.description }} ></p>



                                            {roomDetailsPageData.room_details_data.room_services && roomDetailsPageData.room_details_data.room_services.length > 0 && <span className="price">Room Facilities</span>}
                                            <ul>
                                                {roomDetailsPageData.room_details_data.room_services && roomDetailsPageData.room_details_data.room_services.length > 0 && roomDetailsPageData.room_details_data.room_services.map(function (items, i) {
                                                    return (
                                                        <li key={i}>{items.service_title}</li>
                                                    )

                                                })}

                                            </ul>
                                            {/* <Link className="awe-btn awe-btn-13"
                                                to={{
                                                    pathname: config.PATH + 'booknow',
                                                    search: '?' + (iframe),
                                                }}
                                            >Book Now</Link> */}

                                            <a className="awe-btn awe-btn-13" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>


                                        </div>

                                    </div>

                                </div>



                            </div>
                        </div>}
                    </div>
                </div>
            </section>

            {roomDetailsPageData && roomDetailsPageData.display_default_footer === false && <CustomFooterSection />}
            {roomDetailsPageData && roomDetailsPageData.display_default_footer === true && <Footer />}
            {roomDetailsPageData && <FooterFixed />}
        </div>
    );
}

export default RoomDetails;