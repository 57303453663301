import React, { useState, useContext, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { compose, withProps } from "recompose";
import FooterFixed from './FooterFixed'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import _debounce from 'lodash.debounce'
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment'


function HotelsPage() {

    var hotel_name_url;
    hotel_name_url = window.location.pathname;
    hotel_name_url = hotel_name_url.replace(config.PATH, "");

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [hotelsPageData, setHotelsPageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let name_hotel;
                    let hotel_id;
                    let all_hotel_rooms = []
                    let room_data = [];
                    let hotel_data;
                    let nearby_data;
                    let facilities_data;
                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-hotel-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.hotel_details) {
                        for (let i = 0; i < result.hotel_details.length; i++) {
                            name_hotel = result.hotel_details[i].hotel_name.replace(/[^a-zA-Z ]/g, "");
                            name_hotel = name_hotel.replace(/ +/g, "_");
                            name_hotel = name_hotel.replace(",", "");
                            name_hotel = name_hotel.toLowerCase();
                            if (hotel_name_url === name_hotel) {
                                hotel_id = result.hotel_details[i].hotel_id
                                hotel_data = result.hotel_details[i]
                            }
                        }
                    }

                    if (result && result.room_details.data) {
                        for (var i = 0; i < result.room_details.data.length; i++) {
                            for (var j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (result && all_hotel_rooms && hotel_id) {
                        for (let i = 0; i < all_hotel_rooms.length; i++) {
                            if (all_hotel_rooms[i].hotel_id === hotel_id) {
                                room_data.push(all_hotel_rooms[i]);
                            }
                        }
                    }

                    if (result && result.nearby_places && hotel_id) {
                        for (let i = 0; i < result.nearby_places.length; i++) {
                            if (result.nearby_places[i].hotel_id === hotel_id) {
                                nearby_data = result.nearby_places[i];
                            }
                        }
                    }

                    if (result && result.hotel_facilities && hotel_id) {
                        for (let i = 0; i < result.hotel_facilities.length; i++) {
                            if (result.hotel_facilities[i].hotel_id === hotel_id) {
                                facilities_data = result.hotel_facilities[i];
                            }
                        }
                    }

                    setHotelsPageData({ "hotel_data": hotel_data, "room_data": room_data, "room_section_data": result && result.room_section_data, "nearby_data": nearby_data, "nearby_section_data": result && result.nearby_section_data, "facilities_data": facilities_data, "facilities_section_data": result && result.facility_section_data, "contact_section_data": result && result.contact_section_data, "sub_menu": result && result.sub_menu })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    const [scrolled, setscroll] = useState(false)
    const [display, setDisplay] = useState('rooms')
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });
    const sendData = (value) => {
        setDisplay(value);
    }

    return (
        <div >

            {(hotelsPageData === undefined) ? <div className="loader"></div> : <Header />}

            {hotelsPageData && hotelsPageData.hotel_data && <SliderHotel sliderdata={hotelsPageData.hotel_data} />}

            {hotelsPageData && hotelsPageData.sub_menu && hotelsPageData.sub_menu.length > 0 && <div className="tabbing desktop-sub-menu">
                <ul className="modulelinks meetingmod hotelmode hmenulink">
                    {hotelsPageData.sub_menu.map(function (item, i) {
                        return (
                            <li className="modulelink" key={i}>
                                <div className={(display === item.section_name) ? "nav active" : 'nav'} onClick={() => sendData(item.section_name)}>{item.menu_name}</div>
                            </li>
                        )
                    })}
                </ul>
            </div>}

            {hotelsPageData && hotelsPageData.sub_menu && hotelsPageData.sub_menu.length > 0 && <div className="tabbing mobile-sub-menu">
                <div className="scrolling-wrapper1">
                    <ul className="modulelinks meetingmod hotelmode hmenulink">
                        {hotelsPageData.sub_menu.map(function (item, i) {
                            return (
                                <li className="modulelink card" key={i}>
                                    <div className={(display === item.section_name) ? "nav active" : 'nav'} onClick={() => sendData(item.section_name)}>{item.menu_name}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>}

            {hotelsPageData && hotelsPageData.hotel_data && <DatePickerWidget hoteldata={hotelsPageData.hotel_data} company_data={companyDetails} />}

            {hotelsPageData && hotelsPageData.hotel_data && <HotelIntroduction hoteldata={hotelsPageData.hotel_data} />}

            {hotelsPageData && hotelsPageData.hotel_data && (display === 'overview') && <OverView data={hotelsPageData} />}
            {hotelsPageData && hotelsPageData.room_data && (display === 'rooms') && <RoomHotel roomdata={hotelsPageData.room_data} room_section_data={hotelsPageData.room_section_data} />}
            {hotelsPageData && hotelsPageData.facilities_data && (display === 'services') && <FacilitiesHotel facilitiesdata={hotelsPageData.facilities_data} facilities_section_data={hotelsPageData.facilities_section_data} />}
            {hotelsPageData && hotelsPageData.hotel_data && (display === 'reach') && <ContactHotel contactdata={hotelsPageData.hotel_data} contact_section_data={hotelsPageData.contact_section_data} />}
            {hotelsPageData && hotelsPageData.nearby_data && (display === 'location') && <LocationHotel locationdata={hotelsPageData.nearby_data} location_section_data={hotelsPageData.nearby_section_data} hoteldata={hotelsPageData.hotel_data} />}
            {hotelsPageData && <Footer />}
            {hotelsPageData && <FooterFixed />}
        </div>
    )
}

function HotelIntroduction(props) {
    let hotel_data = props.hoteldata;
    return (
        <div>
            {hotel_data && <div className="container">
                <div className="explor_title row m0">
                    <div className="pull-left">
                        <div className="page-header text-center text-uppercase">
                            <h2 className="hotel_page_title">{hotel_data.hotel_name}</h2>
                        </div>
                    </div>
                </div>
                <div className="contact_info_list">
                    <div className="media">
                        <div className="media-left">
                            <i className="fa fa-map-marker"></i>
                        </div>
                        <div className="media-body">
                            <p>{hotel_data.hotel_address}</p>
                        </div>
                        <div className="media">
                            <div className="media-left">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div className="media-body">
                                <p>{hotel_data.mobile}</p>

                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <p dangerouslySetInnerHTML={{ __html: hotel_data.hotel_description }}></p>
            </div>}
        </div>
    )
}

function OverView(props) {
    let hotelsPageData = props.data;

    return (
        <div>
            {hotelsPageData && <div>
                <RoomHotel roomdata={hotelsPageData.room_data} room_section_data={hotelsPageData.room_section_data} />
                <FacilitiesHotel facilitiesdata={hotelsPageData.facilities_data} facilities_section_data={hotelsPageData.facilities_section_data} />
                <LocationHotel locationdata={hotelsPageData.nearby_data} location_section_data={hotelsPageData.nearby_section_data} hoteldata={hotelsPageData.hotel_data} />
            </div>}
        </div>
    )
};



function RoomHotel(props) {
    let room_section_data = props.room_section_data
    let room_data = props.roomdata
    return (
        <section className="section-accomd awe-parallax bg-14">
            <div className="awe-overlay"></div>
            <div className="container">
                <div className="accomd-modations">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="accomd-modations-header">
                                {room_section_data && <h2 className="heading">{room_section_data.section_title}</h2>}
                                <p></p>
                            </div>
                        </div>
                        <div className="col-md-8 col-md-offset-1">
                            <div className="accomd-modations-content owl-single">
                                <div className="row">
                                    {room_data && room_data.map(function (item, i) {
                                        var send_room_type = item.room_type.replace(/[^a-zA-Z ]/g, "");
                                        send_room_type = send_room_type.replace(/ +/g, "_");
                                        send_room_type = send_room_type.toLowerCase();
                                        return (
                                            <div className="col-xs-6" key={i}>
                                                <div className="accomd-modations-room">
                                                    <div className="img">
                                                        <Link to={{
                                                            pathname: config.PATH + '' + send_room_type,
                                                            roomdetails: item
                                                        }}><img src={config.imageEndpoint + '/' + item.room_image[0]} alt="" /></Link>
                                                    </div>
                                                    <div className="text">
                                                        <h2><Link to={{
                                                            pathname: config.PATH + '' + send_room_type,
                                                            roomdetails: item
                                                        }}>{item.room_type}</Link></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvbN2yXDGSnDIMmUp9JByD39hIsJhDKok&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: props.lat, lng: props.lng }}>
        {props.isMarkerShown && (
            <Marker position={{ lat: props.lat, lng: props.lng }} />
        )}
    </GoogleMap>
));



function FacilitiesHotel(props) {
    let facilities_section_data = props.facilities_section_data
    let facilities_data = props.facilitiesdata

    return (

        <div>
            <div className="container">
                {facilities_section_data && <div className="center_title">
                    <h2 className="heading text-center">{facilities_section_data.section_title}</h2>
                </div>}
            </div>

            <section className="section-about awe-parallax" >
                <div className="container">
                    <div className="about">
                        {facilities_data && facilities_data.facilities && facilities_data.facilities.length > 0 &&
                            <div className="about-item">
                                <div className="text" style={{ width: "100%" }}>
                                    <div className="desc col-md-6">
                                        <ul>
                                            {facilities_data.facilities.map(function (item, i) {
                                                if (i % 2 === 0) {
                                                    return (
                                                        <li key={i}>{item} </li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                    </div>

                                    <div className="desc col-md-6">
                                        <ul>
                                            {facilities_data.facilities.map(function (item, i) {
                                                if (i % 2 !== 0) {
                                                    return (
                                                        <li key={i}>{item} </li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>
        </div>

    )
}

function ContactHotel(props) {

    let contact_section_data = props.contact_section_data
    let contact_data = props.contactdata

    const [name, setname] = useState('');
    const [validname, setvalidname] = useState(true);
    const [email, setemail] = useState('');
    const [validemail, setvalidemail] = useState(true);
    const [number, setnumber] = useState('');
    const [validnumber, setvalidnumber] = useState(true);
    const [subject, setSubject] = useState('');
    const [message, setmessage] = useState('');
    const [successmsg, setsuccessmsg] = useState(true)

    let numbers = [];
    let emails = [];
    if (contact_data) {
        numbers = contact_data.mobile.split(',');
        emails = contact_data.email_id.split(',');
    }

    let hotel_id = contact_data && contact_data.hotel_id

    const contactform = () => {

        if (validname === true && validemail === true && validnumber === true && name.length !== 0 && email.length !== 0 && number.length !== 0) {


            fetch(config.apiEndpoint + '/userwb/send-user-query',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": hotel_id,
                        "name": name,
                        "email": email,
                        "mobile": number,
                        "message": message

                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(false)
                })

            fetch(config.apiEndpoint + '/insert-customer-data',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": hotel_id,
                        "user_name": name,
                        "user_email_id": email,
                        "user_contact_number": number,
                        "user_message": message
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                })

        }
        else {
            if (name.length === 0 && email.length === 0 && number.length === 0) {
                setvalidname(false);
                setvalidemail(false);
                setvalidnumber(false);
            }

        }
    }


    const validateName = (name) => {
        setname(name)
        if (name.match(/[A-Za-z]{2,}/)) {
            setvalidname(true)
        }
        else {
            setvalidname(false)

        }

    }

    const validateSubject = (subject) => {
        setSubject(subject)
    }

    const validateEmail = (email) => {
        setemail(email)

        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            setvalidemail(true)
        }
        else {
            setvalidemail(false)
        }

    }
    const validateNumber = (number) => {
        setnumber(number)
        if (number.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
            setvalidnumber(true)
        }
        else {
            setvalidnumber(false)

        }
    }
    const validateMessage = (message) => {
        setmessage(message)
    }


    return (
        <section className="section-contact awe-parallax" >
            <div className="container">
                <div className="contact">
                    <div className="row">
                        <div className="col-md-6 col-lg-5">
                            <div className="text">
                                {contact_section_data && <h2>{contact_section_data.section_title}</h2>}
                            </div>
                            <div className="contact_info_list">
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-map-marker"></i>
                                    </div>

                                    <div className="media-body">
                                        <p>{contact_data && contact_data.hotel_address}</p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="media-body">

                                        {numbers.map(function (items, i) {
                                            return (
                                                <p key={i}><a href={"tel:" + items}>{items}</a></p>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-envelope-o"></i>
                                    </div>
                                    <div className="media-body">

                                        {emails.map(function (items, i) {
                                            return (
                                                <p key={i}><a href={"mailto:" + items}>{items}</a></p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 col-lg-6 col-lg-offset-1">
                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div hidden={validname} style={{ color: 'red' }}>Invalid Name</div>
                                        <input type="text" className="field-text" id="name" name="name" placeholder="Name" value={name} onChange={(e) => validateName(e.target.value)} />
                                    </div>
                                    <div className="col-sm-6">
                                        <div hidden={validemail} style={{ color: 'red' }}>Invalid Email</div>
                                        <input type="email" className="field-text" id="email" name="email" placeholder="Email" value={email} onChange={(e) => validateEmail(e.target.value)} />
                                    </div>
                                    <div className="col-sm-12">
                                        <div hidden={validnumber} style={{ color: 'red' }}>Invalid Number</div>
                                        <input type="text" className="field-text" id="number" name="number" placeholder="Phone no." value={number} onChange={(e) => validateNumber(e.target.value)} />
                                    </div>
                                    <div className="col-sm-12">
                                        <input type="text" className="field-text" name="subject" placeholder="Subject" value={subject} onChange={(e) => validateSubject(e.target.value)} />
                                    </div>

                                    <div className="col-sm-12">
                                        <textarea className="field-textarea" name="message" id="message" rows="1" placeholder="Message" value={message} onChange={(e) => validateMessage(e.target.value)}></textarea>
                                    </div>
                                    <div className="col-sm-6">
                                        <div hidden={successmsg} style={{ color: 'red', fontSize: 15, fontWeight: 'bold' }}>Submitted Successfully !</div>
                                        <button type="button" className="awe-btn awe-btn-13" onClick={() => contactform()}>SEND</button>
                                    </div>
                                </div>
                                <div id="contact-content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

function DatePickerWidget(props) {

    let hotel_data = props.hoteldata
    let companyDetails = props.company_data
    
    const [focusedInput, setfocusedInput] = useState(null)
    const [fromdate, setFromDate] = useState(moment())
    const [todate, setToDate] = useState(moment(fromdate).add(1, 'days'))
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    let encryptData
    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_data.hotel_id + '|' + "" + '|' + "" + '|' + "" 
        encryptData = btoa(allData);
        // iframe = encryptData

    }

    return (
        <div className="common-page">
        <div className="date-picker-wrap">
            <div className="row">
                <div className=" col-md-4 col-sm-6 form-group input-type-class">
                    <label>Hotel</label>
                    <input type="text" className="form-control input-area" value={hotel_data.hotel_name} readOnly />
                </div>
                <div className=" col-md-4 col-sm-6 form-group date-picker-class">
                    <DateRangePicker
                        startDate={fromdate}
                        startDateId="your_unique_start_date_id"
                        endDate={todate}
                        endDateId="your_unique_end_date_id"
                        onDatesChange={({ startDate, endDate }) => {
                            setFromDate(startDate)
                            setToDate(endDate)
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={focusedInput => setfocusedInput(focusedInput)}
                        displayFormat='DD-MM-YYYY'
                        customArrowIcon="|"
                        block={true}
                    />
                </div>

                <div className=" col-md-4 col-sm-12 form-group button-class">
                    <div className="text-left date-picker-btn">
                        {/* <Link className="book_now_btn" 
                            to={{
                                pathname: config.PATH + 'booknow',
                                search: '?' + (iframe),
                            }}
                        >Book Now</Link> */}

                        <a className="book_now_btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

function SliderHotel(props) {
    let slider_data = props.sliderdata
    return (
        <div className="container-fluid common-page slider-common" style={{ padding: "0px", margin: "0px" }}>
            <OwlCarousel
                className="owl-theme"
                items={1}
                autoplay={true}
                autoplaySpeed={950}
                loop
                nav
                play={5000, 5000}
                autoWidth={false}
            >
                {slider_data && slider_data.image_name && slider_data.image_name.map(function (item, i) {
                    if (item !== null) {
                        return (
                            <div className="item" key={i} >
                                <img src={config.imageEndpoint + '/' + item.image_name} />
                            </div>
                        )
                    }
                })}


            </OwlCarousel>
        </div>

    )
}

function LocationHotel(props) {

    let location_section_data = props.location_section_data
    let location_data = props.locationdata
    let hotel_data = props.hoteldata


    return (
        <section className="get_contact_area places-nearby">
            <div className="container">
                <div className="explor_title row m0">
                    <div className="pull-left">
                        {location_section_data && <div className="center_title">
                            <h2>{location_section_data.section_title}</h2>
                        </div>}
                    </div>
                </div>

                {location_data && location_data.places_details && location_data.places_details.length > 0 ?
                    <div className="row get_contact_inner">
                        <div className="col-md-6">
                            {hotel_data && <div className="map_box_inner">
                                <MyMapComponent isMarkerShown
                                    lat={parseFloat(hotel_data.latitude)}
                                    lng={parseFloat(hotel_data.longitude)} />
                            </div>}
                        </div>
                        <div className="col-md-6">
                            <div className="right_contact_info nearby-places-list">
                                {location_data.places_details.map(function (item, i) {
                                    return (
                                        <div className="row" style={{ padding: "10px" }} key={i}>
                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                <div><i className="fa fa-map-marker" aria-hidden="true"></i> {item.place_name}({item.distance} km)</div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-6 mins">
                                                <div className="pull-right" style={{ marginRight: "20px" }}>{item.time} Mins Drive</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div> :
                    <div className="row get_contact_inner">
                        <div className="col-md-12">
                            {hotel_data && <div>
                                <MyMapComponent isMarkerShown
                                    lat={parseFloat(hotel_data.latitude)}
                                    lng={parseFloat(hotel_data.longitude)} />
                            </div>}
                        </div>
                    </div>
                }
            </div>
        </section>
    )

}

export default HotelsPage;